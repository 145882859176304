/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play-button': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<circle pid="0" opacity=".5" cx="21" cy="21" r="19.385" _fill="#8261FC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M21 38.77c9.814 0 17.77-7.956 17.77-17.77S30.813 3.23 21 3.23 3.23 11.187 3.23 21 11.187 38.77 21 38.77zM21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21z" _fill="#fff"/><path pid="2" d="M17.5 14.7v12.6l9.8-6.3-9.8-6.3z" _fill="#fff"/>'
  }
})
